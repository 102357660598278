import { Tool, LanguageTag } from '@griegconnect/krakentools-react-ui'
import { createContext, useContext } from 'react'
import { NotificationsGlobalProps } from './ui/NotificationsButton'

export type ApplicationRef = Tool | 'admin' | 'explore' | 'go' | 'haulier' | 'manage' | 'monitor' | 'placeholder'

export type ApplicationOptionsContextType = {
  name: string
  tool: Tool
  appRef?: ApplicationRef
  tenantsUrlMatchPathPrefix: string
  tenantsUrlMatchPathParam: string
  reloadOnTenantsChange: boolean
  beforeLogout?: () => Promise<void>
  /**
   * Will only fire once after auth is initialized
   */
  notAuthenticated?: () => Promise<void>
  configurationFilePath: string
  profileModalOpen: string | undefined
  changeProfileModal: (value: string | undefined) => void
  notifications?: NotificationsGlobalProps
  i18n?: {
    /**
     * Supported languages for the application.
     */
    languages?: LanguageTag[]
  }
}

export const ApplicationOptionsContext = createContext<ApplicationOptionsContextType | null>(null)
export const useApplicationOptions = () => useContext(ApplicationOptionsContext)!
