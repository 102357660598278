﻿import React, { useEffect } from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import _ from 'lodash'
import Autocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete'

import { Box, Chip, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Search } from '@griegconnect/krakentools-react-icons'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { Theme } from '@mui/material/styles'

export type Completion<A> = {
  prefix?: string
  label: string
  icon: React.ReactElement
  completions?: (text: string) => Completion<A>[]
  param?: (search: A) => A
  placeholder?: string
  isDate?: boolean
}

export type StoredCompletion = {
  prefix?: string
  label: string
}

const field =
  <T, K extends keyof T>(k: K, f: (value: T[K]) => T[K]) =>
  (t: T) => ({
    ...t,
    [k]: f(t[k]),
  })

const append =
  <K,>(value: (input: string) => K) =>
  (input: string, values?: K[]) =>
    [...(values ? values : []), value(input)]

const search = <T, K extends keyof T>(
  label: string,
  icon: React.ReactElement,
  k: K,
  f: (search: string, field: T[K]) => T[K],
  isDate?: boolean
): Completion<T> => ({
  icon,
  label,
  isDate,
  completions: (input: string) =>
    input.length > 0
      ? [
          {
            prefix: label,
            label: input,
            icon,
            param: (t) => ({ ...t, [k]: f(input, t[k]) }),
          },
        ]
      : [],
})

const options = <T, K extends keyof T>(
  label: string,
  icon: React.ReactElement,
  k: K,
  values: { label: string; value: (input: string, vs: T[K]) => T[K] }[]
): Completion<T> => ({
  icon,
  label,
  completions: (input: string) =>
    values.map<Completion<T>>((option) => ({
      prefix: label,
      label: option.label,
      icon,
      param: (t) => ({ ...t, [k]: option.value(input, t[k]) }),
    })),
})
// eslint-disable-next-line
export const Completion = {
  field,
  append,
  search,
  options,
}

export const filterSort = <A,>(options: Completion<A>[], input: string): Completion<A>[] => {
  const scored = options.map((completion) => {
    const matches = match(completion.label, input)
    const parsed = parse(completion.label, matches)
    const score = _.sum(parsed.map(({ highlight }, idx) => (highlight ? 1 + Math.pow(2, -idx) : 0)))
    return { score, completion }
  })
  const sorted = _.sortBy(
    scored,
    (c) => -c.score,
    (c) => c.completion.label
  )
  const filtered = input.length > 0 ? sorted.filter(({ score }) => score > 0) : sorted
  return filtered.map(({ completion }) => completion)
}

export const renderOption = <A,>(
  _props: any,
  option: Completion<A>,
  { inputValue }: AutocompleteRenderOptionState
): React.ReactElement => {
  const matches = match(option.label, inputValue)
  const parts = parse(option.label, matches)
  return (
    <li {..._props}>
      {option.icon}
      <Box ml={1}>
        {parts.map((part, idx) => (
          <span key={idx} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </Box>
    </li>
  )
}

type TagsRendererProps = {
  variant?: 'small' | 'medium'
}

export const defaultTagsRenderer =
  (props: TagsRendererProps) =>
  <A,>(values: Completion<A>[], getTagProps: AutocompleteRenderGetTagProps): React.ReactNode =>
    values.map((option, index) => (
      <Chip
        color="primary"
        variant="outlined"
        icon={option.icon}
        size={props.variant}
        label={(option.prefix ? `${option.prefix}: ` : '') + option.label}
        {...getTagProps({ index })}
      />
    ))

interface Props<A> {
  completions: Completion<A>[]
  loading?: boolean
  onChange: (value: A) => void
  initialValue: A
  defaultValues?: StoredCompletion[]
  variant?: 'small' | 'medium'
  inputRenderer?: (params: AutocompleteRenderInputParams) => React.ReactNode
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    padding: '5px 10px !important',
    backgroundColor: theme.palette.action.hover,
  },
}))

export const Completable = <A,>({
  completions,
  loading,
  initialValue,
  defaultValues,
  onChange,
  variant,
  inputRenderer,
  className
}: Props<A>) => {
  const classes = useStyles()

  const [values, setValues] = React.useState<Completion<A>[]>([])
  const [inputValue, setInputValue] = React.useState<string>('')
  const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false)

  useEffect(() => {
    if (defaultValues && completions && !loading && values.length === 0) {
      const mappedValues = (defaultValues || []).map((v) => {
        const completion = completions.find((c) => (!!v.prefix && c.label === v.prefix) || c.label === v.label)
        const param =
          completion && completion.completions && completion.completions(v.label).length > 0
            ? completion.completions(v.label).find((c) => c.label === v.label)?.param
            : undefined
        return {
          prefix: v.prefix,
          label: v.label,
          icon: completion?.icon || <Box></Box>,
          param,
        }
      })
      setValues(mappedValues)
    }
  }, [defaultValues, completions, loading])

  const last: Completion<A> | undefined = values[values.length - 1]

  const placeholderLabel = completions.find((c) => c.label === last?.label)?.isDate ? 'dd.MM.yyyy' : 'Search'
  const placeholder = last && last.completions && last.completions('').length === 0 ? placeholderLabel : undefined

  React.useEffect(() => {
    setShowDatePicker(placeholderLabel === 'dd.MM.yyyy')
  }, [placeholder]) // eslint-disable-line

  const defaultInputRenderer = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant="outlined"
      placeholder={placeholder}
      size={variant}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
        endAdornment: (
          <>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                value={''}
                onChange={() => {}}
                onAccept={(date: any) => {
                  const newDate = date as DateTime
                  setInputValue(newDate.toLocaleString(DateTime.DATE_SHORT))
                }}
                inputFormat="dd.MM.yyyy"
                disableFuture={true}
                renderInput={(params) => (
                  <>
                    {showDatePicker && (
                      <Box ref={params.inputRef} sx={{ position: 'absolute', right: '72px' }}>
                        {params.InputProps?.endAdornment}
                      </Box>
                    )}
                  </>
                )}
              />
            </LocalizationProvider>
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )

  const toValue = (current: Completion<A>[]) =>
    current.reduce<A>((acc, v) => (v?.param ? v.param(acc) : acc), initialValue)

  const handleOnChange = (
    _pre: any,
    values: Completion<A>[],
    reason: 'createOption' | 'selectOption' | 'removeOption' | 'blur' | 'clear'
  ) => {
    inputValue.length && setInputValue('')
    if (reason === 'removeOption') {
      setValues(values)
      onChange(toValue(values))
    } else {
      const last = values[values.length - 1]
      const cleaned = last?.param ? values.filter((c) => c.param) : values
      setValues(cleaned)
      if (!last || last?.param) {
        onChange(toValue(cleaned))
      }
    }
  }

  const handleOnInputChange = (_event: React.SyntheticEvent, value: string, reason: string) => {
    if (!showDatePicker || reason !== 'reset') {
      setInputValue(value)
    }
  }

  return (
    <Autocomplete
      multiple
      autoHighlight
      disableCloseOnSelect={Boolean(!last || last.param)}
      options={[...values]}
      value={values}
      inputValue={inputValue}
      defaultValue={defaultValues ? [...defaultValues] : undefined}
      clearOnBlur
      renderOption={renderOption}
      classes={{
        inputRoot: classes.inputRoot,
      }}
      className={className}
      filterOptions={(_ignore, { inputValue }) => {
        const input = inputValue.trim()
        const options = last && last.completions ? last.completions(input) : completions
        return filterSort(options, input)
      }}
      renderInput={inputRenderer ?? defaultInputRenderer}
      renderTags={defaultTagsRenderer({ variant })}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
    />
  )
}
