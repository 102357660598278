import { LicenseInfo } from '@mui/x-license-pro'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import './config/i18n'
import HaulierProvider from './HaulierProvider'

LicenseInfo.setLicenseKey(
  'a8e94b106775b1a24a9729324a1cb889T1JERVI6Mzc1MzUsRVhQSVJZPTE2NzYwMjYxMDAwMDAsS0VZVkVSU0lPTj0x'
)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <HaulierProvider>
    <App />
  </HaulierProvider>
)
