import { Button, Container, Fade, Typography, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { Card } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren } from 'react'
import { ArrowLeft, ArrowRight } from '@griegconnect/krakentools-react-icons'
import { useNavigate } from 'react-router-dom'
import { Box, BoxProps } from '@mui/material'

const useWrapperStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(6),
      },
    },
  })
)

const useTitleStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      fontSize: 40,
      marginRight: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
)

type PageWrapper = PageTitle &
  Pick<BoxProps, 'className'> & {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    backPath?: string
    hideTitle?: boolean
  }

type PageTitle = {
  title: string
  /** Back button (deprecated)
   * @deprecated since version 2.0
   *
   * @type {boolean}
   * @memberof PageTitle
   */
  showBackButton?: boolean
  backPath?: string
  buttons?: React.ReactNode
}

export const Wrapper = (props: PropsWithChildren<PageWrapper>) => {
  const classes = useWrapperStyles()

  return (
    <Box className={props.className} display="flex" justifyContent="center" flex={1}>
      <Fade timeout={500} in={true}>
        <Container maxWidth={props.maxWidth ?? false} className={classes.wrapper}>
          {!props.hideTitle && <Title {...props} />}
          {props.children}
        </Container>
      </Fade>
    </Box>
  )
}

export const Title = (props: PageTitle) => {
  const classes = useTitleStyles()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px)`)

  const onBack = () => {
    props.backPath ? navigate(props.backPath) : navigate(-1)
  }

  return (
    <Box display="flex" alignItems={'center'} flexDirection={'row'} mb={3}>
      {props.showBackButton && <ArrowLeft className={classes.backButton} onClick={onBack} />}
      <Box mr={2} maxWidth="100%">
        <Typography component="h1" variant={isMobile ? 'h1' : 'h2'}>
          {props.title}
        </Typography>
      </Box>
      {props.buttons}
    </Box>
  )
}

interface ICardProps {
  children: React.ReactNode
  id?: string
  style?: React.CSSProperties
  className?: string
  padding?: number
  topLine?: boolean
  toplineColor?: string
}

export const Paper = (props: ICardProps) => {
  const theme = useTheme()
  const padding = props.padding ?? 3
  const topLine = props.topLine ?? false
  const topLineColor = props.toplineColor ?? theme.palette.primary.main

  return (
    <Card
      style={{
        padding: theme.spacing(padding),
        borderTop: !topLine ? 'none' : `2px solid ${topLineColor}`,
        ...props.style,
      }}
      id={props.id}
      elevation={1}
    >
      {props.children}
    </Card>
  )
}

interface PaginationProps {
  page: number
  hasNext: boolean
  onChange: (page: number) => void
}

export const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = (props) => {
  if (!props.hasNext && props.page == 1) {
    return null
  }

  return (
    <>
      <Box display="flex" justifyContent="center" pt={2} pb={9}>
        <Button
          variant="outlined"
          disabled={props.page <= 1}
          onClick={() => props.onChange(props.page - 1)}
          style={{ marginRight: 20 }}
          size="small"
          startIcon={<ArrowLeft />}
        >
          Previous page
        </Button>
        <Button
          size="small"
          variant="outlined"
          disabled={!props.hasNext}
          onClick={() => props.onChange(props.page + 1)}
          endIcon={<ArrowRight />}
        >
          Next page
        </Button>
      </Box>
    </>
  )
}
