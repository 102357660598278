import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles'
import React, { useContext, useState } from 'react'
import Carousel, { CarouselElement } from './components/carousel/carousel'
import SignUp, { SignUpCallbackType } from './components/sign-up/sign-up'
import styles from './grieg-onboarding.module.scss'

export type GriegOnboardingProps = {
  carouselElements: CarouselElement[]
  children: React.ReactNode
  disableCreateAccount?: boolean
  forceSignup?: boolean
  initVisible?: boolean
  /**
   * Only use if you want to override the default flow.
   *
   */
  onClose: () => Promise<void>
  onLogin: () => Promise<void>
  onSignUp: SignUpCallbackType
  privacyPolicyUrl: string
  signUpFields: string[]
  termsOfServiceUrl: string
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#65bbff',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
})

export type Onboarding = {
  setOnboardingVisibility: (display: boolean) => void
  displayOnboarding: boolean
}

export const GriegOnboardingContext = React.createContext<Onboarding | null>(null)

export const useOnboarding = () => useContext(GriegOnboardingContext)!

export const GriegOnboardingProvider = (props: GriegOnboardingProps) => {
  const { carouselElements, termsOfServiceUrl, privacyPolicyUrl, onLogin, onSignUp, signUpFields, children } = props
  const [displayOnboarding, setDisplayOnboarding] = useState(props.initVisible ?? false)
  const [showSignUp, setShowSignUp] = useState(props.forceSignup ?? false)

  const closeSignUp = () => {
    if (props.onClose) {
      props.onClose()
    } else {
      setShowSignUp(false)
    }
  }

  const setOnboardingVisibility = (display: boolean) => {
    setDisplayOnboarding(display)
  }

  return (
    <GriegOnboardingContext.Provider
      value={{
        setOnboardingVisibility,
        displayOnboarding,
      }}
    >
      {displayOnboarding === true && (
        <ThemeProvider theme={theme}>
          <div className={styles.onboardingWrapper} id="onboarding">
            {carouselElements.length > 0 && <Carousel elements={carouselElements} />}
            <div className={styles.actionWrapper}>
              {props.disableCreateAccount ? (
                <>
                  <Button onClick={() => onLogin()} variant="outlined" color="primary">
                    Sign in with Grieg ID
                  </Button>
                  <p>
                    Do you need access?
                    <br /> Please contact your administrator.
                  </p>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: 24 }}>
                    <Button
                      onClick={() => setShowSignUp(true)}
                      variant="outlined"
                      color="primary"
                      sx={{ ml: 1, mr: 1 }}
                    >
                      Create account
                    </Button>
                    <Button
                      id="sign-in"
                      color="primary"
                      variant="outlined"
                      onClick={() => onLogin()}
                      sx={{ ml: 1, mr: 1 }}
                    >
                      Sign in
                    </Button>
                  </div>
                  <Typography variant="body2" className={styles.hint}>
                    To continue create an account (Grieg ID) or sign in.
                  </Typography>
                </>
              )}
            </div>
            <SignUp
              display={showSignUp}
              onClose={closeSignUp}
              termsOfServiceUrl={termsOfServiceUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              signUpFields={signUpFields}
              onSignUp={onSignUp}
            />
          </div>
        </ThemeProvider>
      )}
      {displayOnboarding === false && children}
    </GriegOnboardingContext.Provider>
  )
}
