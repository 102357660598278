import React, { useEffect, useState } from 'react'
import { ApplicationProvider, AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { QueryClient, QueryClientProvider } from 'react-query'
import LogRocketWrapper from './common/logrocket'
import { ReactComponent as Slide1 } from './common/slides/Slide1.svg'
import { ReactComponent as Slide2 } from './common/slides/Slide2.svg'
import { ReactComponent as Slide3 } from './common/slides/Slide3.svg'
import { filterUserAccess } from './common/filterUserAccess'
import { NavAccessDeps, tenantsUrlMatchPathParam, PATH_TENANT_BASE } from './constants'
import {
  TenantContextWrapper,
  Navigation,
  useTenants,
  useConfig,
  ITenantAccess,
  ConfigType,
} from '@griegconnect/krakentools-react-kraken-app'
import { navigations } from './modules/navs'
import { redirectNavs } from './redirectNavs'
import { useHaulierContext } from './HaulierProvider'
import ApiProvider, { useApis } from './lib/ApiProvider'
import LocaleWrapper from './common/locale/LocaleWrapper'

const queryClient = new QueryClient()

export const App = () => {
  const { setTerminals, setHauliers } = useHaulierContext()

  const filterUserAccessHandler = async (
    access: ITenantAccess[],
    config: ConfigType,
    httpClient: (baseUrl: string, useBearerToken?: boolean) => AxiosInstance
  ) => {
    const globalData = await filterUserAccess(access, config, httpClient)
    setTerminals(globalData.terminals)
    setHauliers(globalData.hauliers)

    return globalData.tenants
  }

  return (
    <ApplicationProvider
      name="Haulier"
      tool="terminal"
      appRef="haulier"
      confirmInvitation
      reloadOnTenantsChange
      tenantsUrlMatchPathPrefix={PATH_TENANT_BASE}
      tenantsUrlMatchPathParam={tenantsUrlMatchPathParam}
      filterUserAccess={{ callback: filterUserAccessHandler, type: 'http-client' }}
      onboarding={{
        disableCreateAccount: true,
        carouselElements: [
          {
            component: <Slide1 />,
            title: 'Self-service',
            description: 'Create and assign work orders to the available trucks and drivers.',
          },
          {
            component: <Slide2 />,
            title: 'Automated access',
            description: 'Swift access to terminal due to automated number-plate recognition at the gate.',
          },
          {
            component: <Slide3 />,
            title: 'Availability',
            description: 'Available wherever you are, via web and app – on mobile, tablet and pc.',
          },
        ],
      }}
      i18n={{
        languages: ['en-GB', 'nb-NO', 'sv-SE'],
      }}
    >
      <LocaleWrapper>
        <QueryClientProvider client={queryClient}>
          <LogRocketWrapper>
            <TenantContextWrapper>
              <ApiProvider>
                <HaulierNavigation />
              </ApiProvider>
            </TenantContextWrapper>
          </LogRocketWrapper>
        </QueryClientProvider>
      </LocaleWrapper>
    </ApplicationProvider>
  )
}

const HaulierNavigation = () => {
  const { tenantCompanyType, terminalConfig, terminals } = useHaulierContext()
  const [features, setFeatures] = useState<NavAccessDeps['features']>([])
  const apis = useApis()
  const { activeTenant } = useTenants()
  const config = useConfig()

  useEffect(() => {
    try {
      const getFeatures = async () => {
        const type = tenantCompanyType(activeTenant!.id)
        if (type === 'terminal') {
          const terminal = terminalConfig(activeTenant!.id)
          setFeatures(terminal.config.flags.preadvice_flow ? ['preadvice'] : [])
        } else {
          const activeTerminals = await apis.haulierTerminalsApi.terminals()
          const hasTerminalWithPreadvice = terminals.some(
            (terminal) =>
              terminal.config.flags.preadvice_flow &&
              activeTerminals.some((activeTerminal) => activeTerminal.id === terminal.terminal.company.id)
          )

          setFeatures(hasTerminalWithPreadvice ? ['preadvice'] : [])
        }
      }
      if (activeTenant && apis) {
        getFeatures()
      }
    } catch (error) {
      console.error(error)
      setFeatures([])
    }
  }, [activeTenant, apis]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Navigation
      navigation={[...navigations(config.environment), ...redirectNavs]}
      dependencies={{ activeTenant: activeTenant!, features }}
    />
  )
}
