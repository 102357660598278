import {
  Fade,
  List,
  ListItem as MuiListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon } from '@griegconnect/krakentools-react-icons'
import { ListItemLink } from '@griegconnect/krakentools-react-ui'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { MenuItemType, MenuItemWithSubsType } from '../atoms/menuAtom'
import { menuParentOpenAtom } from '../atoms/menuParentOpenAtom'
import { HoverMenu } from './HoverMenu'

export type MenuItemProps = MenuItemWithSubsType & {
  isNested?: boolean
  isHoverItem?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '100%',
      flexShrink: 0,
      textDecoration: 'none',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('md')]: {
        borderRight: `3px solid #00000000`,
      },
      [theme.breakpoints.down('md')]: {
        borderRight: `4px solid #00000000`,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    defaultItemPadding: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    },
    hoverItemPadding: {

    },
    nested: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    active: {
      [theme.breakpoints.up('md')]: {
        borderRight: `3px solid ${theme.palette.primary.main}`,
      },
      [theme.breakpoints.down('md')]: {
        borderRight: `4px solid ${theme.palette.primary.main}`,
      },
    },
    secondaryAction: {
      [theme.breakpoints.up('md')]: {
        marginRight: '-3px',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '-4px',
      },
    },
    icon: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      flexBasis: theme.spacing(3),
      flexShrink: 0,
      '& svg': {
        width: '100%',
        height: '100%',
        verticalAlign: 'top',
      },
    },
    text: {
      flexBasis: '208px',
      flexShrink: 0,
    },
    defaultText: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    hoverText: {
    },
  })
)

export const MenuItem = (props: MenuItemProps) => {
  const {
    name,
    displayName,
    icon,
    absolutePath,
    activeOnExactMatch,
    items,
    disableLink = false,
    isNested = false,
    isHoverItem = false,
    external,
    callback,
  } = props
  const [leftDrawerState, setLeftDrawerState] = useRecoilState(leftDrawerAtom)
  const [menuParentOpenState, setMenuParentOpenState] = useRecoilState(menuParentOpenAtom)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const childItems = items?.map((item, index) => {
    return (
      <MenuItem
        key={`menu-child-item-${index}`}
        name={item.name}
        displayName={item.displayName}
        absolutePath={item.absolutePath}
        activeOnExactMatch={item.activeOnExactMatch}
        disableLink={disableLink}
        isNested={true}
        external={item.external}
        callback={item.callback}
      />
    )
  })

  const handleToggleParentOpen = () => {
    if (items) {
      const newMenuParentOpenState = menuParentOpenState !== `${absolutePath}-${name}` ? `${absolutePath}-${name}` : ''
      setMenuParentOpenState(newMenuParentOpenState)
    }
  }

  const onItemClickHandler = (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
    if (!isNested && disableLink && items) {
      event.stopPropagation()
      event.preventDefault()
    }
    if (!isNested && !leftDrawerState.isOpen && items && disableLink) {
      // If menu is closed, and clicking a menu item with child navs, open menu
      setLeftDrawerState((currentState) => ({ ...leftDrawerState, isOpen: true }))
    }
    if (!isMdUp && (!items || isNested)) {
      // Close menu after item click on mobile if item has no child navs or is a child element
      setLeftDrawerState((currentState) => ({ ...currentState, isOpen: false }))
    }

    // Set which dropdown should be active when menu is open
    handleToggleParentOpen()
  }

  const toggleClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    handleToggleParentOpen()
  }

  const onMouseEnter = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeave = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ListItem
        className={clsx(classes.item, isNested ? classes.nested : undefined, isHoverItem ? classes.hoverItemPadding : classes.defaultItemPadding)}
        activeClassName={classes.active}
        path={!isNested && disableLink && items ? undefined : absolutePath}
        exact={activeOnExactMatch ?? false}
        items={props.items}
        onClick={onItemClickHandler}
        open={menuParentOpenState === `${absolutePath}-${name}`}
        external={external}
        callback={callback}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        tooltip={(!isNested && !items) ? (displayName ?? name) : undefined}
      >
        {!(!leftDrawerState.isOpen && isMdUp && isNested) && <div className={classes.icon}>{icon}</div>}
        <div className={clsx(classes.text, isHoverItem ? classes.hoverText : classes.defaultText)}>
          <Fade in={leftDrawerState.isOpen || (!leftDrawerState.isOpen && isMdUp && isNested)}>
            <ListItemText primary={displayName ?? name} />
          </Fade>
        </div>
        {items && (!isMdUp || leftDrawerState.isOpen) && (
          <Fade in={leftDrawerState.isOpen} timeout={{ enter: 700, exit: 400 }}>
            <ListItemSecondaryAction className={classes.secondaryAction}>
              <IconButton onClick={toggleClickHandler} size="large">
                {menuParentOpenState === `${absolutePath}-${name}` ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            </ListItemSecondaryAction>
          </Fade>
        )}
        {items && isMdUp && !leftDrawerState.isOpen && anchorEl && <HoverMenu label={displayName ?? name} items={items} anchorEl={anchorEl} />}
      </ListItem>
      {items && (!isMdUp || leftDrawerState.isOpen) ? (
        <Collapse
          in={menuParentOpenState === `${absolutePath}-${name}`}
          timeout={{ enter: 500, exit: 300 }}
          unmountOnExit
        >
          <List component="div" disablePadding>
            {childItems}
          </List>
        </Collapse>
      ) : null}
    </>
  )
}

type ListItemType = Pick<MenuItemWithSubsType, 'items' | 'external' | 'callback'> & {
  className?: string
  activeClassName?: string
  path?: string
  exact?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void
  /**
   * @deprecated Only used in Port to support global actions, e.g. such as new port call
   */
  callback?: MenuItemType['callback']
  open: boolean
  onMouseEnter?: (event: React.MouseEvent) => void
  onMouseLeave?: (event: React.MouseEvent) => void
  tooltip?: React.ReactNode
}

const ListItem: React.FC<React.PropsWithChildren<ListItemType>> = React.forwardRef<HTMLLIElement, ListItemType>(({
  className,
  activeClassName,
  path,
  exact,
  children,
  items,
  onClick,
  callback,
  external,
  open,
  onMouseEnter,
  onMouseLeave,
  tooltip
}, ref) => {
  const { pathname: activePath } = useLocation()

  if (path) {
    if (external) {
      if (tooltip) {
        return (
          <Tooltip title={tooltip} placement="right">
            <MuiListItem
              button={true}
              className={className}
              href={path}
              underline="none"
              color="textPrimary"
              component={Link}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {children}
            </MuiListItem>
          </Tooltip>
        )
      } else {
        return (
          <MuiListItem
            button={true}
            className={className}
            href={path}
            underline="none"
            color="textPrimary"
            component={Link}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </MuiListItem>
        )
      }
    }

    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement="right">
          <ListItemLink
            className={className}
            activeClassName={activeClassName}
            to={path}
            exact={items && open ? true : exact}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </ListItemLink>
        </Tooltip>
      )
    } else {
      return (
        <ListItemLink
          className={className}
          activeClassName={activeClassName}
          to={path}
          exact={items && open ? true : exact}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </ListItemLink>
      )
    }
  } else {
    const activeClosed =
      !open &&
      items &&
      items.some(
        (item) =>
          item.absolutePath === activePath ||
          (item.absolutePath !== undefined && activePath.indexOf(item.absolutePath) === 0)
      )

    const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
      callback?.()
      onClick?.(event)
    }

    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement="right">
          <MuiListItem
            button={true}
            className={`${className} ${activeClosed ? activeClassName : ''}`}
            onClick={onClickHandler}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </MuiListItem>
        </Tooltip>
      )
    } else {
      return (
        <MuiListItem
          button={true}
          className={`${className} ${activeClosed ? activeClassName : ''}`}
          onClick={onClickHandler}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </MuiListItem>
      )
    }
  }
})

export default MenuItem
