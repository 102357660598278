import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

type LinkProps = Pick<MuiLinkProps, 'onClick' | 'href' | 'underline' | 'style' | 'className'>

/**
 * Link component using react router
 */
const Link: React.FC<React.PropsWithChildren<LinkProps>> = (props) => {
  const { href, underline = 'hover', children, ...rest } = props
  return (
    <MuiLink component={RouterLink} to={href ? href : '#'} underline={underline} {...rest}>
      {children}
    </MuiLink>
  )
}

export default Link
