import React from "react";
import { Tool, GriegThemeProvider } from '@griegconnect/krakentools-react-ui'
import { themeConfigurationAtom } from "../atoms/themeAtom";
import { useRecoilValue } from "recoil";

export type ThemeWrapperProps = {
    tool?: Tool
    children?: React.ReactNode
}

export const ThemeWrapper = (props: ThemeWrapperProps) => {

    const { tool, children } = props;
    const themeConfiguration = useRecoilValue(themeConfigurationAtom)

    return (
        <GriegThemeProvider
            useScopedCssBaseline={false}
            tool={tool ?? 'kraken'}
            overrideGlobalStyles={true}
            paletteType={themeConfiguration.palette}
        >
            {children}
        </GriegThemeProvider>
    )
}

export default ThemeWrapper;