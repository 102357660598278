import { Search as SearchIcon } from '@griegconnect/krakentools-react-icons'
import { InputAdornment, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import { v4 } from 'uuid'

import { Paper } from '../paper'
import { Debouncer } from '../utils'
import SearchBarResultItem from './searchbar-result-item'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    listBox: {
      maxHeight: 'unset',
      minHeight: 'unset',
      '& li': {
        marginBottom: 4,
      },
    },
    searchfieldNoResults: {
      '& .MuiInputBase-root': {
        padding: '6px 0 6px 10px !important',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '0',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: '0',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
      },
    },
    searchfieldResults: {
      '& .MuiInputBase-root': {
        padding: '6px 0 6px 10px !important',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(150, 150, 150,0.4)',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottomStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        borderBottom: '1px solid ',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottomStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    paper: {
      maxHeight: 'unset',
      minHeight: 'unset',
      marginTop: 0,
      borderRadius: 0,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
    },
    inputResults: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    inputNoResults: {
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
    },
  })
})

export type ResultItemChip = {
  color: 'green' | 'yellow' | 'grey' | 'red'
  text: string
}

export type ResultItem<D = any> = {
  value?: D
  title: string
  subtitle: string
  icon?: any
  chip?: ResultItemChip
}

export type SearchBarProps = {
  queryFn: (query: string) => Promise<ResultItem[] | undefined>
  onChange?: (chosenValue: any) => void
  onBlur?: () => void
  placeholder?: string
  searchDebounceDelay?: number
  // To be able to reference the input-field in the DOM (focus())
  inputFieldName?: string
  clearOnBlur?: boolean
  clearOnEscape?: boolean
  disableReset?: boolean
  className?: string
}

const debouncer = new Debouncer()
const SearchBar = (props: SearchBarProps) => {
  const [results, setResults] = useState<ResultItem[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const classes = useStyles()
  const inputFieldName = props.inputFieldName ?? v4()

  const handleInputChange = (_evt: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'reset' && props.disableReset) {
    } else {
      setInputValue(value)
      if (value === '') {
        setLoading(false)
        setResults([])
      } else if (value) {
        doSearch(value)
      }
    }
  }

  const doSearch = (query: string) => {
    debouncer.bounce(() => {
      setLoading(true)
      props
        .queryFn(query)
        .then((r) => {
          if (r !== undefined && r.length === 0) {
            setResults([
              {
                title: 'No results',
                subtitle: '',
              },
            ])
          } else {
            setResults(r)
            setLoading(false)
          }
        })
        .catch(() => {
          setResults([
            {
              title: 'Error',
              subtitle: 'Some error occured when searching...',
            },
          ])
        })
    }, props.searchDebounceDelay || 200)
  }

  const getOptionLabel = (option: ResultItem) => {
    return option.title || ''
  }

  const filterOptions = (options: ResultItem[]) => options

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ResultItem) => (
    <SearchBarResultItem resultItem={option} {...props} />
  )

  const handleFocus = (evt: any) => {
    doSearch(evt.target.value)
  }

  const PaperComponent = (props: any) => {
    return <Paper elevation={24} className={classes.paper} {...props}></Paper>
  }

  const handleChange = (_event: any, value: string | ResultItem | null) => {
    if (props.onChange) {
      setResults([])
      setInputValue('')
      setLoading(false)
      props.onChange(value)
      // props.onBlur && props.onBlur();
    }
  }

  // const handleBlur = () => {
  //   // setResults([]);
  //   // setLoading(false);
  //   props.onBlur && props.onBlur();
  // };
  /*
   * We're adding a custom attribute to the searchbars inputfield (inputfieldname) to make it accessible from outside
   */
  return (
    <>
      <Paper>
        <Autocomplete
          filterOptions={filterOptions}
          noOptionsText={'no hits'}
          loading={loading}
          options={results ?? []}
          freeSolo={true}
          clearOnBlur={props.clearOnBlur ?? true}
          inputValue={inputValue}
          clearIcon={<SearchIcon />}
          onInputChange={handleInputChange}
          onBlur={props.onBlur}
          onChange={handleChange}
          autoHighlight={true}
          onFocus={handleFocus}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          PaperComponent={PaperComponent}
          clearOnEscape={props.clearOnEscape ?? true}
          blurOnSelect={true}
          classes={{
            paper: classes.paper,
            listbox: classes.listBox,
          }}
          className={props.className}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              className={
                results === undefined || results.length > 0 ? classes.searchfieldResults : classes.searchfieldNoResults
              }
              hiddenLabel={true}
              inputProps={{
                ...params.inputProps,
                inputfieldname: inputFieldName,
                'data-testid': 'searchbar-input',
              }}
              InputProps={{
                ...params.InputProps,
                placeholder: props.placeholder,
                endAdornment: (
                  <InputAdornment position="end" style={{ paddingRight: 8 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Paper>
    </>
  )
}
export default SearchBar
