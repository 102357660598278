import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  DataGridPro,
  GridRowEditStopParams,
  MuiEvent,
  useGridApiRef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStartParams,
  gridEditRowsStateSelector,
  GridRowModel,
  GridRowId,
  GridRowsProp,
  GridValidRowModel,
  DataGridProProps,
  GridColumnVisibilityModel,
  GridRowParams,
  GridSelectionModel,
  GridCallbackDetails,
} from '@mui/x-data-grid-pro'
import { DateTime } from 'luxon'
import { z } from 'zod'

import { InlineEditDataGridFooter } from './InlineEditDataGridFooter'
import InlineEditDataGridToolbar from './InlineEditDataGridToolbar'
import { InlineEditDataGridActionsProps } from './row-actions/InlineEditDataGridActionsMenu'
import { InlineEditDataGridCommands } from './row-actions/inlineEditDataGridCommands'
import {
  inlineGridColumns,
  InLineEditDataGridColumn,
  isEmptyObject,
  getDefaultColumnConfig,
  isNewItem,
  mapEditStateToRow,
} from './Utils'

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
  '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnSeparator': {
    display: 'flex',
  },
}))

const useStyles = makeStyles(() => ({
  pinnedColumns: {
    boxShadow: 'none',
  },
  pinnedColumnHeaders: {
    boxShadow: 'none',
  },
}))

export type CopyFieldsOnCreatedRowToNewRowType<Row extends GridValidRowModel> = {
  fields: Array<keyof Row>
}

export enum Warning {
  AlreadyEditing = 'AlreadyEditing',
  RequiredValuesMissing = 'RequiredValuesMissing',
}

export enum Error {
  UnknownError = 'UnknownError',
}

type InlineEditDataGridConfiguration = Partial<{
  onWarning?: (warning: Warning) => void
  onError?: (error: Error) => void
}>

export type InLineEditDataGridProps<
  Row extends GridValidRowModel,
  ZodValidationSchema extends z.ZodSchema = z.ZodSchema
> = {
  rowIdentifier: string
  rows: GridRowsProp<Row>
  columns: InLineEditDataGridColumn<Row>[]
  affectedFieldIdentifier?: string
  /**
   * Zod validation schema used to validate the row before saving it, if not provided, no validation will be performed
   */
  validationSchema?: ZodValidationSchema
  onDeleteRow?: (id: GridRowId) => void
  /**
   * Callback triggered after multi-select updates
   */
  onRowsUpdated?: (rows: Row[]) => void
  onNewRow?: (id: number, skipCreationInGrid?: boolean) => Row
  /**
   * If provided, onNewRow must be provided as well,
   * @param row The row that was just created
   */
  onNewRowThroughModal?: (row: Row) => void
  isRowEditable?: (row: Row) => boolean
  onEditClick?: (id: GridRowId, row: Row) => void
  copyFieldsOnCreatedRowToNewRow?: CopyFieldsOnCreatedRowToNewRowType<Row>
  disableEdit?: boolean
  disableDelete?: boolean
  gridColumnVisibilityModel?: GridColumnVisibilityModel
  actionMenuProps?: InlineEditDataGridActionsProps<InlineEditDataGridCommands, Row>
  className?: string
  /**
   * Render-prop used to display available actions for the selected rows
   */
  selectActionsComponent?: (
    selectedRows: Row[],
    removeSelection: () => void,
    updateRows: (updatedRows: Row[]) => void
  ) => ReactElement
} & Omit<DataGridProProps<Row>, 'onRowDoubleClick' | 'selectionModel' | 'onSelectionModelChange'> &
  InlineEditDataGridConfiguration

export default function InLineEditDataGrid<
  Row extends GridValidRowModel,
  ZodValidationSchema extends z.ZodSchema = z.ZodSchema
>({
  rows,
  validationSchema,
  columns,
  affectedFieldIdentifier,
  onNewRow,
  onNewRowThroughModal,
  onDeleteRow,
  onRowsUpdated,
  processRowUpdate,
  onEditClick,
  disableEdit,
  disableDelete,
  rowIdentifier,
  components,
  componentsProps,
  gridColumnVisibilityModel,
  copyFieldsOnCreatedRowToNewRow,
  actionMenuProps,
  className,
  selectActionsComponent,
  onWarning,
  onError,
  isRowEditable,
  ...other
}: InLineEditDataGridProps<Row, ZodValidationSchema>) {
  const classes = useStyles()

  const [selectedRows, setSelectedRows] = useState<Row[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [internalColumns, setInternalColumns] = useState(columns)
  const [rowBeingEdited, setRowBeingEdited] = useState<GridValidRowModel | null>(null)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    ...gridColumnVisibilityModel,
    multipleEditedColumn: false,
  })

  const apiRef = useGridApiRef()

  const handleCancelClick = useCallback(
    (id: GridRowId) => {
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      }))
      if (id < 0) onDeleteRow?.(id)
      setRowBeingEdited(null)
    },
    [onDeleteRow]
  )

  const onRowEditStop = useCallback(
    (params: GridRowEditStopParams<Row>, event: MuiEvent) => {
      event.defaultMuiPrevented = true
      if (params.reason === 'escapeKeyDown') {
        handleCancelClick(params.id)
        return false
      } else if (params.reason === 'enterKeyDown') return false

      const editRowState = gridEditRowsStateSelector(apiRef.current.state)[params.id]

      if (validationSchema) {
        const row = mapEditStateToRow(editRowState, params.row)
        const validationResult = validationSchema.safeParse(row)
        if (!validationResult.success) {
          onWarning?.(Warning.RequiredValuesMissing)
          return false
        }
      }

      const error = Object.values(editRowState).some((value) => value.error)

      if (error) {
        onError?.(Error.UnknownError)
        return false
      }

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [params.id]: { mode: GridRowModes.View },
      }))
      return true
    },
    [validationSchema, columns, apiRef, handleCancelClick]
  )

  const internalProcessRowUpdate = async (newRow: GridRowModel<Row>, oldRow: GridRowModel<Row>) => {
    if (processRowUpdate) {
      const result = await processRowUpdate(newRow, oldRow)
      if (copyFieldsOnCreatedRowToNewRow && onNewRow && isNewItem(oldRow[rowIdentifier])) {
        const { model, row } = createRow(onNewRow)

        copyFieldsOnCreatedRowToNewRow.fields.forEach((field) => (row[field] = result[field]))

        setRowBeingEdited(row)
        setRowModesModel({
          ...model,
        })

        return result
      }
      setRowBeingEdited(null)
      return result
    }
    setRowBeingEdited(null)
    return newRow
  }

  const internalHandleProcessRowUpdate = () => {
    if (rowBeingEdited)
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [rowBeingEdited[rowIdentifier]]: { mode: GridRowModes.Edit },
      }))
  }

  const onRowEditStart = (_params: GridRowEditStartParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true
  }

  const validateState = useCallback((): boolean => {
    if (!isEmptyObject(gridEditRowsStateSelector(apiRef.current.state))) {
      onWarning?.(Warning.AlreadyEditing)
      return false
    }
    return true
  }, [apiRef])

  const handleSaveClick = useCallback(
    (id: GridRowId) => onRowEditStop({ id } as GridRowEditStopParams<Row>, { defaultPrevented: false } as MuiEvent),
    [onRowEditStop]
  )

  const handleDeleteClick = useCallback((id: GridRowId) => onDeleteRow?.(id), [onDeleteRow])

  const handleEditClick = useCallback(
    (id: GridRowId, row: Row) => {
      if (onEditClick) {
        if (!rowBeingEdited) onEditClick(id, row)
      } else if (validateState() && !disableEdit) {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit },
        }))
        setRowBeingEdited(row)
      }
    },
    [disableEdit, onEditClick, rowBeingEdited, validateState]
  )

  const createRow = (
    newRowFn: (id: number, skipCreationInGrid?: boolean) => Row,
    id?: GridRowId,
    skipCreationInGrid?: boolean
  ) => {
    const newRowId = -DateTime.now().toMillis()
    const newRow = newRowFn(newRowId, skipCreationInGrid)
    const newModel = id
      ? { [id]: { mode: GridRowModes.View }, [newRowId]: { mode: GridRowModes.Edit } }
      : { [newRowId]: { mode: GridRowModes.Edit } }

    return { row: newRow, model: newModel }
  }

  const handleNewClick = (id?: GridRowId, force?: boolean) => {
    if (onNewRow) {
      if (force || validateState()) {
        const { model, row } = createRow(onNewRow, id)

        setRowBeingEdited(row)
        setRowModesModel((oldModel) => ({
          ...oldModel,
          ...model,
        }))
      }
    }
  }

  const handleNewWithModalClick = (id?: GridRowId) => {
    if (onNewRow && onNewRowThroughModal) {
      if (validateState()) {
        const { row } = createRow(onNewRow, id, true)
        onNewRowThroughModal(row)
      }
    }
  }

  useEffect(() => {
    const mappedColumns = columns.map((column) => ({
      ...column,
      ...getDefaultColumnConfig(column),
    }))

    const rowsAffectedList: (string | number)[] = []
    //	if (rowBeingEdited && affectedFieldIdentifier) {
    //		rowsAffectedList = rows
    //			.filter((row) => row[affectedFieldIdentifier] === rowBeingEdited[affectedFieldIdentifier])
    //			.map((row) => row[affectedFieldIdentifier])
    //
    //		if (rowsAffectedList.length > 1) enqueue(t('InlineEditDataGrid.Feedback.MultipleRowsAffected'), 'warning')
    //	}

    setColumnVisibilityModel({
      ...gridColumnVisibilityModel,
      multipleEditedColumn: rowsAffectedList.length > 1,
    })

    const defaultColumns = inlineGridColumns<Row>({
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      handleSaveClick,
      rowModesModel,
      rowsAffectedList,
      affectedFieldIdentifier,
      disableEdit,
      disableDelete,
      actionMenuProps,
    })

    const columnsWithActions = [defaultColumns[0], ...mappedColumns, defaultColumns[1]]

    setInternalColumns(columnsWithActions as InLineEditDataGridColumn<Row>[])
  }, [
    actionMenuProps,
    affectedFieldIdentifier,
    columns,
    disableDelete,
    disableEdit,
    gridColumnVisibilityModel,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    rowBeingEdited,
    rowModesModel,
    rows,
  ])

  const internalDoubleClick = (params: GridRowParams<Row>) => {
    if (isRowEditable && !isRowEditable(params.row)) return
    if (gridEditRowsStateSelector(apiRef.current.state)[params.id]) return
    if (validateState() && !disableEdit) {
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [params.id]: { mode: GridRowModes.Edit },
      }))
      setRowBeingEdited(params.row)
    }
  }

  const onSelectionChangeHandler = async (selectionModel: GridSelectionModel, _details: GridCallbackDetails) => {
    const matchingRows = rows.filter((row) => selectionModel.includes(row[rowIdentifier]))
    setSelectedRows(matchingRows)
  }

  const removeSelectionHandler = async () => {
    setSelectedRows([])
  }

  const updateSelectionHandler = useCallback(
    async (updatedRows: Row[]) => {
      const allRowsUpdated = rows.reduce<Row[]>((acc, curr) => {
        const currentRow = updatedRows.find((row) => row[rowIdentifier] === curr[rowIdentifier])
        if (currentRow) {
          acc.push(currentRow)
        } else {
          acc.push(curr)
        }
        return acc
      }, [])
      onRowsUpdated?.(allRowsUpdated)
    },
    [onRowsUpdated, rowIdentifier, rows]
  )

  return (
    <StyledBox>
      <DataGridPro
        classes={{
          pinnedColumns: classes.pinnedColumns,
          pinnedColumnHeaders: classes.pinnedColumnHeaders,
        }}
        className={className}
        onSelectionModelChange={onSelectionChangeHandler}
        selectionModel={selectedRows.map((sr) => sr[rowIdentifier])}
        onRowDoubleClick={internalDoubleClick}
        apiRef={apiRef}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel({
            ...gridColumnVisibilityModel,
            ...newModel,
          })
        }
        editMode="row"
        onRowEditStop={onRowEditStop}
        onRowEditStart={onRowEditStart}
        getRowId={(row) => row[rowIdentifier]}
        rows={rows}
        columns={internalColumns}
        pinnedColumns={{
          right: ['actions'],
        }}
        processRowUpdate={internalProcessRowUpdate}
        onProcessRowUpdateError={internalHandleProcessRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        rowModesModel={rowModesModel}
        showCellRightBorder
        showColumnRightBorder
        components={{
          ...components,
          Footer: InlineEditDataGridFooter,
          Toolbar: InlineEditDataGridToolbar,
        }}
        componentsProps={{
          ...componentsProps,
          footer: {
            ...componentsProps?.footer,
            ...(onNewRow && { handleNewClick }),
            ...(onNewRowThroughModal && { handleNewWithModalClick }),
          },
          toolbar: {
            WrappedComponent: components?.Toolbar,
            ...(onNewRow && { handleNewClick }),
            ...componentsProps?.toolbar,
          },
        }}
        onCellKeyDown={(params, event) => {
          if (event.ctrlKey && event.code === 'ArrowDown') {
            event.preventDefault()
            if (!isEmptyObject(gridEditRowsStateSelector(apiRef.current.state))) {
              if (
                onRowEditStop({ id: params.id } as GridRowEditStopParams<Row>, { defaultPrevented: true } as MuiEvent)
              )
                handleNewClick(params.id, true)
            } else handleNewClick(undefined, true)
          }
        }}
        disableSelectionOnClick
        {...other}
      />
      {selectActionsComponent && selectActionsComponent(selectedRows, removeSelectionHandler, updateSelectionHandler)}
    </StyledBox>
  )
}
