type KrakenHttpProblemItem = {
  field?: string
  path: string
  message: string
}

type KrakenHttpProblem = {
  data: null | KrakenHttpProblemItem[]
  detail: null | string
  status: number
  title: string
  type: string
}

export const httpErrorsAsString = (httpProblem: KrakenHttpProblem): string | undefined => {
  const errors: string[] = []
  if (httpProblem) {
    if (httpProblem.data) {
      for (const problem of httpProblem.data) {
        if (problem.field) {
          errors.push(`${problem.message} (field: ${problem.field})`)
        } else {
          errors.push(`${problem.path} (${problem.message})`)
        }
      }
    }
    if (errors.length === 0 && httpProblem.detail) {
      errors.push(httpProblem.detail)
    }
    if (errors.length === 0 && httpProblem.title) {
      errors.push(httpProblem.title)
    }
    if (errors.length === 0) {
    }
  }
  return errors.length === 0 ? undefined : errors.join(', ')
}
