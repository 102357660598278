import React, { useCallback, useState } from 'react'

import { TimeCalendar } from '@griegconnect/krakentools-react-icons'
import { IconButton, InputBase, InputBaseProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTime } from 'luxon'

const fontFamily = ['Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(',')

const CustomTextField = styled((props: InputBaseProps) => <InputBase {...props} />)(() => ({
  padding: '0 9px',
  fontFamily: fontFamily,
  fontSize: '14px',
}))

const endAdornment = (onClick: () => void) => {
  return (
    <IconButton onClick={onClick}>
      <TimeCalendar />
    </IconButton>
  )
}

interface IDateSelectorProps {
  value: DateTime | null
  onChange: (value: DateTime | null) => void
  autoFocus?: boolean
  label?: string
  error?: boolean
  required?: boolean

  maxDate?: DateTime
  minDate?: DateTime

  className?: string
}

const DateSelector: React.FC<React.PropsWithChildren<IDateSelectorProps>> = ({
  value,
  onChange,
  autoFocus,
  label,
  error: err,
  required,
  maxDate,
  minDate,
  className,
}) => {
  const [datetime, setDateTime] = useState<DateTime | null>(value)
  const [open, setOpen] = useState<boolean>(false)

  const handleOnChange = useCallback(
    (value: DateTime | null) => {
      setDateTime(value)
      onChange(value)
      setOpen(false)
    },
    [onChange]
  )

  const handlePickerOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleOnClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <DatePicker
      mask="__.__.____" // <- Temporary warning suppression
      //There is a bug in DatePicker with the AdapterLuxon, placeholder is not shown. Throws a warning if mask is not defined.
      // Related Issues:
      // - https://github.com/mui/mui-x/issues/4755
      // - https://github.com/moment/luxon/issues/1197
      autoFocus={autoFocus}
      label={label}
      value={datetime}
      onChange={handleOnChange}
      onClose={handleOnClose}
      maxDate={maxDate}
      minDate={minDate}
      open={open}
      renderInput={({ ref, inputRef, inputProps, InputProps, error }) => {
        return (
          <CustomTextField
            fullWidth
            className={className}
            value={inputProps?.value}
            ref={ref}
            {...InputProps}
            placeholder={inputProps?.placeholder}
            inputRef={inputRef}
            inputProps={inputProps}
            endAdornment={endAdornment(handlePickerOpen)}
            error={error || err}
            required={required}
          />
        )
      }}
    />
  )
}

export default DateSelector
