import React, { useState, useEffect } from 'react';

import CarouselText from './carousel-text';
import CarouselComponent from './carousel-component';
import CarouselNavigation from './carousel-navigation';

import styles from '../../grieg-onboarding.module.scss';

export type CarouselElement = {
  component: React.ReactNode;
  slideInAngle?: number;
  title: string;
  description: string;
};

export type SlideState = {
  index: number;
  status: 'active' | 'inactive';
  top: string;
  left: string;
  opacity: string;
};

export type Props = {
  elements: CarouselElement[];
};

export const Carousel = (props: Props) => {
  const { elements } = props;

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [cancelSlideEffect, setCancelSlideEffect] = useState(false);

  useEffect(() => {
    const changeSlideInterval = window.setInterval(() => {
      if (!cancelSlideEffect) {
        if (activeSlide + 1 >= elements.length) {
          setActiveSlide(0);
        } else {
          setActiveSlide(activeSlide + 1);
        }
      }
    }, 3500);
    return () => {
      window.clearInterval(changeSlideInterval);
    };
  });

  const changeSlideByClick = (index: number) => {
    setActiveSlide(index);
    setCancelSlideEffect(true);
  };

  const getComponentPosition = (index: number, currentIndex: number, angle?: number) => {
    if (angle) {
      if (index === currentIndex) {
        return { top: '0vw', left: '0vw' };
      } else if (index < currentIndex) {
        return { top: '15vw', left: '-30vw' };
      } else if (index > currentIndex) {
        return { top: '-15vw', left: '30vw' };
      } else {
        return { top: '0vw', left: '0vw' };
      }
    } else {
      if (index === currentIndex) {
        return { top: '0vw', left: '0vw' };
      } else if (index < currentIndex) {
        return { top: '15vw', left: '-30vw' };
      } else if (index > currentIndex) {
        return { top: '-15vw', left: '30vw' };
      } else {
        return { top: '0vw', left: '0vw' };
      }
    }
  };

  const componentRender = elements.map((el: CarouselElement, index) => {
    return (
      <CarouselComponent
        key={`carousel-component-${index}`}
        component={el.component}
        transitionTimingFunction={'cubic-bezier(.62,.28,.23,.99)'}
        transitionTime={500}
        top={getComponentPosition(index, activeSlide, el.slideInAngle).top}
        left={getComponentPosition(index, activeSlide, el.slideInAngle).left}
        opacity={index === activeSlide ? 1 : 0}
      />
    );
  });

  const textRender = elements.map((el: CarouselElement, index) => {
    return (
      <CarouselText
        key={'carouselText' + index}
        transitionTimingFunction={'cubic-bezier(.62,.28,.23,.99)'}
        transitionTime={500}
        opacity={index === activeSlide ? 1 : 0}
        title={el.title}
        description={el.description}
      />
    );
  });

  return (
    <>
      <div className={styles.carouselWrapper}>
        {componentRender}
        <div className={styles.carouselInnerWrapper}>
          {textRender}
          <CarouselNavigation
            onSlideChange={(index: number) => changeSlideByClick(index)}
            activeIndex={activeSlide}
            elements={elements}
          />
        </div>
      </div>
    </>
  );
};

export default Carousel;
