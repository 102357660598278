import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { List } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { menuItemsAtom } from '../atoms/menuAtom'

import MenuItem from './MenuItem'
import MenuController from './MenuController'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  })
)

export const Menu = () => {
  const classes = useStyles()
  const menuItems = useRecoilValue(menuItemsAtom)

  return (
    <>
      <MenuController />
      <List className={classes.wrapper}>
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={`menu-item-${index}`}
              name={item.name}
              displayName={item.displayName}
              icon={item.icon}
              absolutePath={item.absolutePath}
              activeOnExactMatch={item.activeOnExactMatch}
              disableLink={item.disableLink}
              items={item.items}
              external={item.external}
              callback={item.callback}
            />
          )
        })}
      </List>
    </>
  )
}

export default Menu
