import { Fade, List, ListItem, ListItemText, ListSubheader, Paper, Popper, Typography } from '@mui/material'
import React, { useMemo } from 'react'

import { MenuItemType } from '../atoms/menuAtom'
import { MenuItem } from './MenuItem'

export type HoverMenuProps = {
  anchorEl: Element
  label: React.ReactNode
  items: MenuItemType[]
}

export const HoverMenu: React.FC<React.PropsWithChildren<HoverMenuProps>> = (props) => {

  const onClickPopperHandler = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const menuItems = useMemo(() => {
    return props.items.map((item, index) => {
      return (
        <MenuItem
          key={`menu-child-item-${index}`}
          name={item.name}
          displayName={item.displayName}
          absolutePath={item.absolutePath}
          activeOnExactMatch={item.activeOnExactMatch}
          disableLink={item.external ? true : false}
          isNested={true}
          isHoverItem={true}
          external={item.external}
          callback={item.callback}
        />
      )
    })
  }, [props.items])

  return (
    <Popper
      style={{ zIndex: 1300 }}
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      placement="right-start"
      transition
      onClick={onClickPopperHandler}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={8}>
            <List dense={true}>
              <ListItem button={true} disabled={true}>
                <ListItemText>{props.label}</ListItemText>
              </ListItem>
              {menuItems}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
