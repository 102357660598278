import { PaletteMode } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { themeConfigurationAtom } from '../atoms/themeAtom'

type UseUserInterfaceType = {
  hideLeftDrawer: () => void
  showLeftDrawer: () => void
  palette: PaletteMode
}

export const useUserInterface = (): UseUserInterfaceType => {
  const [leftDrawerState, setLeftDrawerState] = useRecoilState(leftDrawerAtom)
  const themeConfiguration = useRecoilValue(themeConfigurationAtom)

  const hideLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: true }))
  }

  const showLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: false }))
  }

  return {
    hideLeftDrawer,
    showLeftDrawer,
    palette: themeConfiguration.palette,
  }
}
