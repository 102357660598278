import { ListItemButton } from '@mui/material'
import { ListItemProps as MuiListItemProps } from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import * as React from 'react'
import { Link, useMatch } from 'react-router-dom'

type ListItemBaseProps = Pick<MuiListItemProps, 'className'> & {
  activeClassName?: string
  exact?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  onMouseEnter?: (event: React.MouseEvent) => void
  onMouseLeave?: (event: React.MouseEvent) => void
  onMouseOver?: (event: React.MouseEvent) => void
}

type ListItemLinkProps = ListItemBaseProps & {
  icon?: React.ReactElement
  text: string
  to: string
}

const ListItemLink = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<ListItemLinkProps>>(
  (
    {
      children,
      className,
      activeClassName,
      to,
      exact,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      icon = undefined,
      text = undefined,
      ...rest
    },
    ref
  ) => {
    const match = useMatch(`${to}${exact ? '' : '/*'}`)

    return (
      <ListItemButton
        className={`${className} ${match ? activeClassName : ''}`}
        component={Link}
        to={to}
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        role={undefined}
        {...rest}
      >
        {icon ? (
          <>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={text} />
          </>
        ) : (
          children
        )}
      </ListItemButton>
    )
  }
)

export default ListItemLink
