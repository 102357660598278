import { Close } from '@griegconnect/krakentools-react-icons'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import React from 'react'
import { TextField } from '@mui/material'

const drawerWidth = 300
const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
      padding: '20px',
      top: '56px',
      paddingBottom: '64px',
    },
    filterHeader: {
      marginBottom: '8px',
      marginTop: '10px',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: '1199 !important' as unknown as number,
      '& .apply-button': {
        marginTop: '8px',
      },
      '& .apply-button-griditem': {
        margin: '0 auto',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    select: {
      '& .select': {
        width: '100%',
        marginBottom: '12px',
      },
    },
    selectMenu: {
      top: '41px',
    },
  })

interface IFilterDrawerProps extends WithStyles<typeof styles> {
  filterConfig: IFilter
  filterSelections: IFilterSelections
  onClose: () => void
  onChange: (filterSelection: IFilterSelections) => void
  open: boolean
  DrawerProps?: DrawerProps
  filterTitle?: string
  clearButtonLabel?: string
  className?: string
}

export interface IFilter {
  [filterSectionKey: string]: IFilterSectionTypes
}

type IFilterSectionTypes =
  | IFilterSectionCheckboxes
  | IFilterSectionDate
  | IFilterSectionRadiobuttons
  | IFilterSectionSelect
  | IFilterSectionSwitches
type IFilterSectionType = 'checkboxes' | 'date' | 'radiobuttons' | 'select' | 'switches'

interface IFilterSectionDefaults {
  label: string
  type: IFilterSectionType
}

export interface IFilterSectionValues {
  values: IFilterSectionValuesKeyValue[]
}

interface IFilterSectionCheckboxes extends IFilterSectionDefaults, IFilterSectionValues {
  type: 'checkboxes'
}

interface IFilterSectionDate extends IFilterSectionDefaults {
  type: 'date'
  fromDate?: Date
  toDate?: Date
  timeOfDayType?: 'default' | 'start' | 'end'
  /**
   *  Looks like this is deprecated after upgrading from material-ui@4 -> mui@5
   *
   * @deprecated
   */
  displayType?: 'dialog' | 'inline'
}

interface IFilterSectionRadiobuttons extends IFilterSectionDefaults, IFilterSectionValues {
  type: 'radiobuttons'
}

export interface IFilterSectionSelect extends IFilterSectionDefaults, IFilterSectionValues {
  type: 'select'
  multiple: boolean
  placeholder?: string
}

interface IFilterSectionSwitches extends IFilterSectionDefaults, IFilterSectionValues {
  type: 'switches'
}

export interface IFilterSectionValuesKeyValue {
  label: string
  /**
   * An empty string value '' will always remove the filter selection
   *
   * @type {string}
   * @memberof IFilterSectionValues
   */
  value: string
}

export interface IFilterSelections {
  [filterSelectionKey: string]: string[]
}

class FilterDrawer extends React.Component<IFilterDrawerProps> {
  constructor(props: IFilterDrawerProps) {
    super(props)
    this.state = {
      open: props.open,
    }
  }

  onBeforeChangeFilter(newFilterSelections: IFilterSelections) {
    // TODO: Validate and clean up unused selections...
    Object.keys(newFilterSelections).map((key) => {
      const keyValue = newFilterSelections[key]
      let deleteSelection = keyValue === undefined || keyValue.length === 0 ? true : false
      if (deleteSelection === false && keyValue.length > 0) {
        for (const value of keyValue) {
          if (value === undefined || value === null || value === '') {
            deleteSelection = true
            break
          }
        }
      }
      if (deleteSelection) {
        delete newFilterSelections[key]
      }
    })
    this.props.onChange(newFilterSelections)
  }

  onChangeFilterSectionValues =
    (newValues: IFilterSectionValuesKeyValue, filterSectionKey: string) =>
    (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
      const newFilterSelections = this.props.filterSelections
      const valueMatchIndex = newFilterSelections[filterSectionKey]
        ? newFilterSelections[filterSectionKey].indexOf(newValues.value)
        : undefined
      if (valueMatchIndex === undefined) {
        newFilterSelections[filterSectionKey] = []
        newFilterSelections[filterSectionKey].push(newValues.value)
      } else if (valueMatchIndex === -1) {
        newFilterSelections[filterSectionKey].push(newValues.value)
      } else {
        newFilterSelections[filterSectionKey].splice(valueMatchIndex, 1)
      }
      this.props.onChange(newFilterSelections)
    }

  onChangeFilterSectionSingleValue =
    (filterSectionKey: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const newFilterSelections = this.props.filterSelections
      newFilterSelections[filterSectionKey] = [value]
      this.onBeforeChangeFilter(newFilterSelections)
    }

  renderCheckboxes(
    filterCheckboxes: IFilterSectionCheckboxes,
    filterSelectionValues: string[],
    filterSectionKey: string
  ) {
    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterCheckboxes.label}</FormLabel>
        <FormGroup>
          {filterCheckboxes.values.map((value: IFilterSectionValuesKeyValue) => {
            const checked = filterSelectionValues ? filterSelectionValues.indexOf(value.value) >= 0 : false
            return (
              <FormControlLabel
                key={`${filterSectionKey}-value${value.value}`}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={this.onChangeFilterSectionValues(value, filterSectionKey)}
                    value={value.value}
                  />
                }
                label={value.label}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    )
  }

  renderRadiobuttons(
    filterRadiobuttons: IFilterSectionRadiobuttons,
    filterSelectionValues: string[],
    filterSectionKey: string
  ) {
    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterRadiobuttons.label}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={filterSelectionValues && filterSelectionValues.length > 0 ? filterSelectionValues[0] : ''}
          onChange={this.onChangeFilterSectionSingleValue(filterSectionKey)}
        >
          {filterRadiobuttons.values.map((value: IFilterSectionValuesKeyValue) => {
            return (
              <FormControlLabel
                key={`${filterSectionKey}-value${value.value}`}
                control={<Radio />}
                value={value.value}
                label={value.label}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    )
  }

  renderSwitches(filterSwitches: IFilterSectionSwitches, filterSelectionValues: string[], filterSectionKey: string) {
    return (
      <>
        <FormControl margin="normal" component="fieldset" fullWidth={true}>
          <FormLabel component="legend">{filterSwitches.label}</FormLabel>
          <FormGroup>
            {filterSwitches.values.map((value: IFilterSectionValuesKeyValue) => {
              const checked = filterSelectionValues ? filterSelectionValues.indexOf(value.value) >= 0 : false
              return (
                <FormControlLabel
                  key={`${filterSectionKey}-value${value.value}`}
                  labelPlacement="end"
                  control={
                    <Switch
                      checked={checked}
                      onChange={this.onChangeFilterSectionValues(value, filterSectionKey)}
                      value={value.value}
                      color="primary"
                    />
                  }
                  label={value.label}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </>
    )
  }

  renderDate(filterDate: IFilterSectionDate, filterSelectionValues: string[], filterSectionKey: string) {
    const onChange = (date: any) => {
      const newFilterSelections = this.props.filterSelections
      if (date !== null) {
        let newDate = date.toJSDate() as Date
        if (filterDate.timeOfDayType) {
          if (filterDate.timeOfDayType === 'start') {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0)
          }
          if (filterDate.timeOfDayType === 'end') {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999)
          }
        }
        newFilterSelections[filterSectionKey] = [newDate.toJSON()]
      } else {
        delete newFilterSelections[filterSectionKey]
      }
      this.onBeforeChangeFilter(newFilterSelections)
    }

    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterDate.label}</FormLabel>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DesktopDatePicker
            renderInput={(props) => <TextField {...props} fullWidth={true} variant="standard" />}
            label="Date"
            value={(filterSelectionValues && filterSelectionValues[0]) ?? null}
            onChange={onChange}
            inputFormat={'dd.MM.yyyy'}
            minDate={filterDate.fromDate ?? undefined}
            maxDate={filterDate.toDate ?? undefined}
          />
        </LocalizationProvider>
      </FormControl>
    )
  }

  renderSelect(filterSelect: IFilterSectionSelect, filterSelectionValues: string[], filterSectionKey: string) {
    const handleChange = (event: any) => {
      const eventTarget = event.target
      const newFilterSelections = this.props.filterSelections
      newFilterSelections[filterSectionKey] = filterSelect.multiple ? [...eventTarget.value] : [eventTarget.value]

      this.onBeforeChangeFilter(newFilterSelections)
    }

    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterSelect.label}</FormLabel>
        <Select
          fullWidth={true}
          multiple={filterSelect.multiple}
          displayEmpty={true}
          value={filterSelectionValues === undefined ? [] : filterSelectionValues}
          onChange={handleChange}
          input={<Input />}
          // tslint:disable-next-line: jsx-no-lambda
          renderValue={(selected) => {
            const selectedValues: undefined | string[] = selected as unknown as undefined | string[]
            if (selectedValues === undefined || selectedValues.length === 0) {
              return <em>{filterSelect.placeholder ? filterSelect.placeholder : 'No filtering'}</em>
            } else if (selectedValues.length <= 2) {
              const matches = filterSelect.values.filter((item) => selectedValues.indexOf(item.value) > -1)
              const retValues: string[] = []
              for (const item of matches) {
                retValues.push(item.label)
              }
              return retValues.join(', ')
            } else {
              return `${selectedValues.length} items selected`
            }
          }}
        >
          {filterSelect.values.map((item: IFilterSectionValuesKeyValue) => (
            <MenuItem key={item.value} value={item.value}>
              {filterSelect.multiple && (
                <Checkbox
                  checked={filterSelectionValues === undefined ? false : filterSelectionValues.indexOf(item.value) > -1}
                />
              )}
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  handleCloseDrawer = () => {
    this.props.onClose()
  }

  handleResetFilter = () => {
    const userFilter = this.props.filterSelections
    for (const key in userFilter) {
      if (userFilter.hasOwnProperty(key)) {
        delete userFilter[key]
      }
    }
    this.onBeforeChangeFilter(userFilter)
  }

  render() {
    const {
      classes,
      filterSelections,
      filterConfig,
      open,
      DrawerProps,
      filterTitle = 'Filter',
      clearButtonLabel = 'Clear filter',
    } = this.props

    const items = Object.keys(filterConfig).map((filterSectionKey: string) => {
      const filterSection = filterConfig[filterSectionKey]
      const filterSelection = filterSelections[filterSectionKey]
      let component
      switch (filterSection.type) {
        case 'checkboxes':
          component = this.renderCheckboxes(filterSection, filterSelection, filterSectionKey)
          break
        case 'select':
          component = this.renderSelect(filterSection, filterSelection, filterSectionKey)
          break
        case 'switches':
          component = this.renderSwitches(filterSection, filterSelection, filterSectionKey)
          break
        case 'radiobuttons':
          component = this.renderRadiobuttons(filterSection, filterSelection, filterSectionKey)
          break
        case 'date':
          component = this.renderDate(filterSection, filterSelection, filterSectionKey)
          break
        default:
          component = null
      }
      return <React.Fragment key={`filter-group-${filterSectionKey}`}>{component}</React.Fragment>
    })

    return (
      <Drawer
        onClose={this.handleCloseDrawer}
        open={open}
        anchor="right"
        className={`${classes.drawer} ${this.props.className}`}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
        {...DrawerProps}
      >
        <div style={{ position: 'relative' }}>
          <Grid
            container={true}
            direction="row"
            className={classes.filterHeader}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item={true}>
              <Typography variant="body1">{filterTitle}</Typography>
            </Grid>
            <Grid item={true}>
              <Typography>
                <IconButton onClick={this.handleCloseDrawer} size="large">
                  <Close />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>

          {items}
          <Grid item={true} xs="auto" className="apply-button-griditem">
            <Button variant="outlined" className="apply-button" onClick={this.handleResetFilter}>
              {clearButtonLabel}
            </Button>
          </Grid>
        </div>
      </Drawer>
    )
  }
}

class FilterUtilitiesClass {
  cleanSelections(filter: IFilter, selections: IFilterSelections): IFilterSelections {
    const cleanedSelections = { ...selections }
    Object.keys(selections).forEach((selectionKeyName: string, _index: number) => {
      if (filter.hasOwnProperty(selectionKeyName)) {
        const filterSection = filter[selectionKeyName]
        let cleanedValues: string[] = []
        if (filter[selectionKeyName].type === 'select') {
          cleanedValues = [
            ...this._cleanSelectionWithMultipleValues(
              (filterSection as IFilterSectionSelect).values,
              selections[selectionKeyName]
            ),
          ]
        }
        if (cleanedValues.length === 0) {
          delete cleanedSelections[selectionKeyName]
        } else {
          cleanedSelections[selectionKeyName] = cleanedValues
        }
      }
    })
    return cleanedSelections
  }

  private _cleanSelectionWithMultipleValues(values: IFilterSectionValuesKeyValue[], selections: string[]): string[] {
    const cleanedSelections: string[] = []
    for (const valueItem of values) {
      if (selections.indexOf(valueItem.value) >= 0) {
        cleanedSelections.push(valueItem.value)
      }
    }
    return cleanedSelections
  }
}

export const FilterUtilities = new FilterUtilitiesClass()

export default withStyles(styles)(FilterDrawer)
